<template>
  <div class="pd20x flex1">
      <div class="contentBox h100pct ofA">
        <div class="filter" style="padding:10px 20px">
          <!-- <div>
            <a-button type="primary" @click="openClasses">开课</a-button>
          </div> -->
          <div>
            <label>搜索：</label>
            <a-select v-model="selectVal" style="width: 120px" >
              <a-select-option :value="item.val" v-for="(item,index) in option" :key="index">
                {{item.name}}
              </a-select-option>
            </a-select>
            <a-input-search placeholder="请输入搜索内容" v-model="searchInput" style="width: 300px" @search="onSearch" class="mr10px"/>
          </div>
        </div>
        <a-table
          :columns="columns"
          :data-source="data"
          :rowKey="'uid'"
          :pagination="{pageSize: page.pageSize, total}"
          @change="changePage"
        >
          <template slot="headimgurl" slot-scope="text">
            <a-avatar icon="user" :src="text"/>
          </template>
          <template slot="localip" slot-scope="text">
            {{text}}
          </template>
          <template slot="studytime" slot-scope="text">
            {{text}}
          </template>
          <template slot="activeTime" slot-scope="text">
            {{text}}
          </template>
          <template slot="operation" slot-scope="text, record">
            <a-space>
              <span class="csP" style="color:#7ebae5" @click="()=>{ currentItem = record ; visible = true }">加时</span>
              </a-space>
          </template>
        </a-table>
      </div>
    </div>
</template>
<script>
const columns = [
  { title: '头像', dataIndex: 'headimgurl', align: 'center', scopedSlots: { customRender: 'headimgurl' } },
  { title: '昵称', dataIndex: 'nickname', align: 'center' },
  { title: '学号', dataIndex: 'num', align: 'center' },
  { title: '电话', dataIndex: 'phone', align: 'center' },
  { title: '登陆IP', dataIndex: 'localip', align: 'center' },
  // { title: '最近登录时间', dataIndex: 'content2', scopedSlots: { customRender: 'content2' }, align: 'center' },
  // { title: '操作', dataIndex: 'operation', scopedSlots: { customRender: 'operation' }, align: 'center' }
];
import * as http from '@/libs/analysis'
export default {
    name: 'students',
    data(){
        return{
          data:[],
          columns,
          option : [
            { name: '微信名', val: 'nickname'},
            { name: '学号', val: 'num'},
            { name: '电话', val: 'phone'},
          ],
          searchInput: '',
          visible: false,
          selectVal: 'nickname',
          deliverOpen: false,
          currentItem: null,
          filter:{},
          page:{
            pageSize: 15,
            page: 1
          },
          total: 0
        }
    },
    mounted(){
      this.getData()
    },
    methods:{
      getData(){
        http.getAnalysisUserList({...this.page, ...this.filter}).then(res => {
          let {total, data} = res
          this.data = data
          this.total = total
        })
      },
      openClasses({uid}){
        this.$router.push({
          path: '/practice/courseLimit',
          query:{
            uid
          }
        })
      },
      onSearch(){
        this.filter = {}
        this.filter[this.selectVal] = this.searchInput
        this.getData()
      },
      changePage({current}){
        this.page.page = current
        this.getData()
      }
    }
}
</script>
